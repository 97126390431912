<template>
    <div class="nurseDetail">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    护士详情
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='nurse_name'>
            <div class='name_cont'>
                王娟 <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/woman.png' class='sex_icon'/>
            </div>
            <div>
                <img src='https://static.yihu365.cn/img/h5Img/assets/img/order/nurse.png' class='nurse_icon'/>
            </div>
        </div>
        <div class='flex_start list'>
            <div class='list_t'>职位</div>
            <div>护士长</div>
        </div>
        <div class='flex_start list'>
            <div class='list_t'>职位</div>
            <div>护士长</div>
        </div>
        <div class='flex_start list'>
            <div class='list_t'>地址</div>
            <div>北京市北京市</div>
        </div>
        <div class='flex_start list tips'>
            <img src='https://static.yihu365.cn/img/h5Img/assets/img/order/rz.png'/>
            <div>经医护到家与国家卫健委官方认证执业护士</div>
        </div>
        <div class='line'></div>

        <div class='content'>
            <div class='title'>可提供的护理服务</div>
            <div class='introduce'>
                <div>个人简介</div>
                <div class='introduce_cont'>擅长护理擅长护理擅长护理擅长护理擅长护理擅长护理擅长护理擅长护理</div>
            </div>
            <div class='introduce'>
                <div>职业资格证</div>
                <div>
                    <img src='https://static.yihu365.cn/img/h5Img/assets/img/order/zs.png'/>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'



export default {
  name: 'nurseDetail',
  components: {

  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let payShow = ref(false);
    let concelShow = ref(false);
    let checked = ref(1);
    const active = ref(0);
    return {
        router,
        checked,
        active,
        payShow,
        concelShow
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .nurse_name{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin:25px;
        margin-bottom: 10px;
        padding:25px;
        font-size: 44px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313131;
        border-bottom: 1px solid #F2F2F2;
        div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .nurse_icon{
            width: 90px;
        }
        .sex_icon{
            width: 34px;
            height: 34px;
            margin-left:20px;
        }
    }
    .list{
        padding:10px 40px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        .list_t{
            color:#919090;
            width: 200px;
        }
    }
    .tips{
        color:#FF9E00;
        padding-bottom: 40px;
        margin-top:20px;
        img{
            width: 34px;
            height: 34px;
        }
    }
    .content{
        padding:30px;
        .title{
            font-size: 34px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
            border-bottom: 1px solid #F2F2F2;
            padding-bottom:25px;
            padding-left:5px;
        }
    }
    .introduce{
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding:25px 5px;
        border-bottom: 1px solid #F2F2F2;
        .introduce_cont{
            color: #919090;
            margin-top:15px;
        }
        img{
            width: 550px;
            margin-top:25px;
        }
    }
</style>
