import { render } from "./nurseDetail.vue?vue&type=template&id=080891a2&scoped=true"
import script from "./nurseDetail.vue?vue&type=script&lang=js"
export * from "./nurseDetail.vue?vue&type=script&lang=js"

import "./nurseDetail.vue?vue&type=style&index=0&id=080891a2&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-080891a2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "080891a2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('080891a2', script)) {
    api.reload('080891a2', script)
  }
  
  module.hot.accept("./nurseDetail.vue?vue&type=template&id=080891a2&scoped=true", () => {
    api.rerender('080891a2', render)
  })

}

script.__file = "src/views/order/nurseDetail/nurseDetail.vue"

export default script